.react-tel-input .country-list {
  & .country {
    color: var(--primaryTextColor);
    &:hover,
    &.highlight {
      background-color: var(--primaryTextColor) !important;
      color: var(--primaryColor);
      .dial-code {
        color: rgba(var(--primaryColorRgb), 0.6) !important;
      }
    }
    // &.highlight {
    //   background-color: var(--primaryTextColor) !important;
    //   color: var(--primaryColor);
    //   .dial-code {
    //     color: rgba(var(--primaryColorRgb), 0.6) !important;
    //   }
    // }
    .dial-code {
      color: rgba(var(--primaryTextColorRgb), 0.6) !important;
    }
  }
}

// .react-tel-input .ru {
//   background-position: -48px -290px !important;
// }

.sign-timer {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  margin-top: 10px;

  &__title {
    margin-bottom: 10px;
  }
  &__date {
    font-size: 23px;
    line-height: 25px;
    font-family: "Lato", monospace;
    font-weight: bold;
    color: var(--secondaryTextColor);
  }
}

.sign-up-section {
  &__title--error {
    word-break: break-word;
    margin-top: 30px;
  }
  &__title {
    margin-bottom: 30px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 45px;
    text-align: center;

    &_svg {
      margin-top: 3px;
      margin-right: 15px;

      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
        path {
          fill: var(--secondaryTextColor);
        }
      }
    }
  }
  &__avatar {
    margin-bottom: 20px;
  }
  &__subtitle {
    width: 100%;
    padding: 0 5px;

    margin-top: -10px;
    margin-bottom: 15px;

    text-align: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      color: rgba(var(--primaryTextColorRgb), 0.4);
    }
  }

  &__error-block {
    margin-top: -10px;
    margin-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: var(--secondaryTextColor);
    text-shadow: 2px 2px 6px rgba(var(--secondaryTextColorRgb), 0.3);
    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;

      path {
        fill: var(--secondaryTextColor);
      }
    }
  }

  &__buttons-block {
    margin-bottom: 30px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__search-button {
    margin-right: 20px;
    padding: 10px 20px;

    border: 2px solid rgba(var(--primaryTextColorRgb), 0.35);
    color: var(--primaryTextColor);
    border-radius: 10px 0px;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;

    transition: all 0.3s ease-in-out;

    &:hover {
      border: 2px solid var(--secondaryTextColor);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // inputs
  &__inputs-block {
    width: 100%;
    max-width: 350px;
  }

  &__input-wrapper {
    margin-bottom: 20px;
    width: 100%;
    // max-width: 350px;
    position: relative;

    &_required {
      &::after {
        content: "";
        position: absolute;
        top: -5.5px;
        right: -5.5px;
        border-radius: 50%;
        background: radial-gradient(
          var(--secondaryTextColor) 0%,
          var(--secondaryTextColor) 40%,
          var(--primaryColor) 40%,
          var(--primaryColor) 100%
        );
        width: 15px;
        height: 15px;
      }
    }
  }

  &__input {
    padding: 16px 20px;
    &--phone {
      padding-left: 54px;
    }
    width: 100%;

    border: 2px solid rgba(var(--primaryTextColorRgb), 0.35);
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    box-sizing: border-box;
    border-radius: 10px 0px;
    outline: none;

    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px !important;
    line-height: 18px;

    transition: border 0.15s ease-in-out;

    &:disabled {
      background-color: var(--secondaryColor);
    }

    &::placeholder {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: rgba(var(--primaryTextColorRgb), 0.4);
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &:focus {
      border: 2px solid rgba(var(--primaryTextColorRgb), 0.8);
    }

    &_error {
      border: 2px solid var(--secondaryTextColor);
    }
  }

  &__dropdown {
    position: fixed !important;
    background-color: var(--primaryColor) !important;
    color: var(--primaryTextColor);
    border: 2px solid rgba(var(--primaryTextColorRgb), 0.4);
    &:hover {
      background-color: var(--primaryTextColor);
      color: var(--primaryColor);
    }
  }

  &__input-error-message {
    margin: 5px -5px -10px 0;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: var(--secondaryTextColor);

    display: flex;
    justify-content: flex-end;

    p {
      margin: 0 5px 5px 5px;

      flex: 2;
    }

    button {
      margin: 0 5px 5px 5px;

      flex: 1;
      color: var(--secondaryTextColor);
    }
  }

  &__reg-button ~ &__prev-button {
    margin-top: 20px;
  }
  &__reg-button + &__reg-button {
    margin-top: 20px;
  }

  &__prev-button ~ &__skip-button {
    margin-top: 20px;
  }

  &__reg-button {
    padding: 18px 22px;
    width: 100%;
    max-width: 350px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--secondaryTextColor);

    border-radius: 10px 0px;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: var(--primaryColor);

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: var(--primaryTextColor);
    }
  } // inputs

  &__prev-button,
  &__skip-button {
    display: flex;
    align-items: center;
    max-width: 350px;

    padding: 16px 30px;
    border: 2px solid rgba(var(--primaryTextColorRgb), 0.4);
    color: var(--primaryTextColor);
    border-radius: 10px 0px;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;

    transition: all 0.2s ease-in-out;

    &:hover {
      border: 2px solid var(--secondaryTextColor);
    }

    svg {
      path {
        fill: var(--primaryTextColor);
      }
    }
  }

  &__prev-button {
    svg {
      margin-right: 10px;
    }
  }

  &__skip-button {
    svg {
      margin-left: 10px;
      transform: rotate(180deg);
    }
  }

  &__skip-button {
    margin-bottom: 20px;

    border: 2px solid #cacaca;

    svg {
      margin-left: 10px;
    }
  }

  &__info-block {
    // margin-bottom: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    font-size: 17px;
    line-height: 25px;
  }

  &__info-question {
    margin-right: 10px;
  }

  &__info-button {
    position: relative;

    color: var(--secondaryTextColor);
    cursor: pointer;
  }

  &__second-info-block {
    margin-bottom: 10px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    // font-size: 17px;
    // line-height: 25px;
  }

  &__second-info-question {
    margin-right: 10px;
  }

  &__second-info-button {
    position: relative;

    color: var(--secondaryTextColor);
    cursor: pointer;
  }
}

@media only screen and (max-width: 480px) {
  .sign-up-section {
    &__title {
      font-size: 24px;
      line-height: 30px;

      // word-break: break-all;
      // text-overflow: ellipsis;

      &_svg {
        width: 30px;
        height: 30px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
