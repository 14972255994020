html,
body,
div,
span,
button,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  margin-left: 0px;
}
/* li {
  list-style-type: none;
} */

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button:active,
button:focus {
  outline: none;
}

strong {
  font-weight: 700;
}

input {
  font-size: inherit;
  outline: none;
  box-shadow: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap'); */

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Light.woff2") format("woff2"),
    url("/fonts/Lato-Light.woff") format("woff"),
    url("/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-LightItalic.woff2") format("woff2"),
    url("/fonts/Lato-LightItalic.woff") format("woff"),
    url("/fonts/Lato-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Regular.woff2") format("woff2"),
    url("/fonts/Lato-Regular.woff") format("woff"),
    url("/fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Italic.woff2") format("woff2"),
    url("/fonts/Lato-Italic.woff") format("woff"),
    url("/fonts/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Medium.woff2") format("woff2"),
    url("/fonts/Lato-Medium.woff") format("woff"),
    url("/fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-MediumItalic.woff2") format("woff2"),
    url("/fonts/Lato-MediumItalic.woff") format("woff"),
    url("/fonts/Lato-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Semibold.ttf") format("truetype"),
    url("/fonts/Lato-Semibold.woff2") format("woff2"),
    url("/fonts/Lato-Semibold.woff") format("woff");

  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-SemiboldItalic.woff2") format("woff2"),
    url("/fonts/Lato-SemiboldItalic.woff") format("woff"),
    url("/fonts/Lato-SemiboldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Bold.woff2") format("woff2"),
    url("/fonts/Lato-Bold.woff") format("woff"),
    url("/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-BoldItalic.woff2") format("woff2"),
    url("/fonts/Lato-BoldItalic.woff") format("woff"),
    url("/fonts/Lato-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Heavy.woff2") format("woff2"),
    url("/fonts/Lato-Heavy.woff") format("woff"),
    url("/fonts/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-HeavyItalic.woff2") format("woff2"),
    url("/fonts/Lato-HeavyItalic.woff") format("woff"),
    url("/fonts/Lato-HeavyItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Black.woff2") format("woff2"),
    url("/fonts/Lato-Black.woff") format("woff"),
    url("/fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-BlackItalic.woff2") format("woff2"),
    url("/fonts/Lato-BlackItalic.woff") format("woff"),
    url("/fonts/Lato-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

/* Montserrat Fonts*/

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;

  src: url("/fonts/montserrat-v25-latin_cyrillic-regular.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-regular.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;

  src: url("/fonts/montserrat-v25-latin_cyrillic-300.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-300.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-300.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;

  src: url("/fonts/montserrat-v25-latin_cyrillic-600.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-600.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-600.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;

  src: url("/fonts/montserrat-v25-latin_cyrillic-500.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-500.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-500.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;

  src: url("/fonts/montserrat-v25-latin_cyrillic-700.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-700.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-700.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/montserrat-v25-latin_cyrillic-800.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-800.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-800.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;

  src: url("/fonts/montserrat-v25-latin_cyrillic-900.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-900.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-900.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;

  src: url("/fonts/montserrat-v25-latin_cyrillic-300italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-300italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-300italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;

  src: url("/fonts/montserrat-v25-latin_cyrillic-italic.woff2") format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;

  src: url("/fonts/montserrat-v25-latin_cyrillic-500italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-500italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-500italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;

  src: url("/fonts/montserrat-v25-latin_cyrillic-700italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-700italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-700italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;

  src: url("/fonts/montserrat-v25-latin_cyrillic-600italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-600italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-600italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;

  src: url("/fonts/montserrat-v25-latin_cyrillic-900italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-900italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-900italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;

  src: url("/fonts/montserrat-v25-latin_cyrillic-800italic.woff2")
      format("woff2"),
    url("/fonts/montserrat-v25-latin_cyrillic-800italic.woff") format("woff"),
    url("/fonts/montserrat-v25-latin_cyrillic-800italic.ttf") format("truetype");
  font-display: swap;
}
