:root {
  --primaryColor: #ffffff;
  --secondaryColor: #f8f8f8;
  --primaryTextColor: #2b2b2b;
  --secondaryTextColor: #ee2424;

  --primaryColorRgb: 255, 255, 255;
  --secondaryColorRgb: 248, 248, 248;
  --primaryTextColorRgb: 43, 43, 43;
  --secondaryTextColorRgb: 238, 36, 36;

  // default colors
  --main-color: #2b2b2c;
  --main-bg-color: #ffffff;
  --secondary-bg-color: #f8f8f8;

  --secondary-color: #da052c;
  --secondary2-color: #aaa; // #666

  // club colors
  --color2: #ee2424;
  --color1: #2b2b2b;
  --white: #fff;
  --orange: orange;
}
