@import "./reset.css";
@import "./swiper-styles.scss";
@import "./sign-up-section.scss";
@import "./root";

.container {
  max-width: 1270px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // padding: 0 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

html {
  scroll-behavior: smooth;
}

b,
strong {
  font-weight: 800;
}

em,
i {
  font-style: italic;
}

body {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  line-height: 1.42857143;

  background-color: var(--primaryColor);
  color: var(--primaryTextColor);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;

  ::-webkit-scrollbar {
    appearance: none;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    width: 11px;
    // height: 0;
    // background-color: transparent;
    background-color: var(--secondaryColor);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    // linear-gradient(
    //   90deg,
    //   transparent 4px,
    //   var(--primaryColor) 4px,
    //   var(--primaryColor) 7px,
    //   transparent 7px
    // );
    //rgba(#cacaca, 1)
    // background-color: var(--primaryColor);
    border-radius: 11px;
    padding: 0 4px;
  }
}

body > div:first-child,
div#__next,
body > :nth-child(2) {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

a {
  cursor: pointer;
}

a img {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.f {
  display: flex;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

iframe {
  border-width: 0px;
}

h1 {
  font-size: 50px;
  line-height: 61px;
  font-weight: 800;
}

h1 {
  font-family: "Montserrat", sans-serif;
}

button {
  font-size: 15px;
  font-family: "Lato", sans-serif;
  line-height: 18px;
}

strong {
  font-weight: 700;
}
em {
  font-style: italic;
}

a {
  color: var(--primaryTextColor);
}

.description-html {
  strong {
    font-weight: 700;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  a {
    font-weight: 600;

    text-decoration: underline;
    cursor: pointer;
  }

  p {
    display: block;

    margin-block-end: 1em;
    &:not(:first-child) {
      margin-block-start: 1em;
    }
  }

  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}

.button-block {
  display: flex;
}

.button-wrap {
  display: flex;
  flex-wrap: wrap;
}

.blackout--enabled:after {
  background-color: rgba(0, 0, 0, 0.7);
}

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 28px;
  border-radius: 10px 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  background-color: var(--secondaryTextColor);

  color: var(--primaryColor);
  transition: all 0.3s ease-in-out;

  // & + & {
  //   margin-left: 20px;
  // }
  &:hover {
    background-color: var(--primaryTextColor);
  }
  &--reverse {
    background-color: var(--primaryTextColor);
  }
}

.schedule-toggle {
  margin: 30px auto 30px;
  // max-width: 1270px;
  display: flex;
  // flex-direction: column;
  // align-self: center;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    // align-self: center;
    margin-bottom: 35px;
    margin-right: 20px;
  }

  &__button {
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    color: var(--primaryTextColor);
    font-weight: bold;
    font-size: 17px;
    line-height: 27px;
    transition: color 0.1s ease-in;

    &:hover {
      color: var(--secondaryTextColor);
    }

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 0;
      height: 3px;

      background-color: var(--secondaryTextColor);
      transition: width 0.4s ease-in;
    }
    &-active {
      color: var(--secondaryTextColor);
      &::after {
        width: 50%;
      }
    }
  }
}

.main {
  display: flex;
  flex: 1;
}

.main-padding-block {
  padding: 100px 20px;
}
.main-padding-block-one {
  padding: 50px 20px 0;
}

.subscription__none-title {
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
}

.main-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 55px;
  &--center {
    text-align: center;
  }
  &--mb {
    margin-bottom: 50px;
  }
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
}

.accent-title {
  margin-left: 15px;
  color: var(--secondary-color);
}

@media only screen and (max-width: 1300px) {
  .schedule-toggle {
    justify-content: center;
    &__button-wrapper {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 780px) {
  .schedule-toggle {
    flex-direction: column;
    align-items: center;
    margin: 20px auto 20px;
    &__button-wrapper {
      align-self: center;
      margin-right: 0px;
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main-padding-block {
    padding: 70px 20px;
  }

  .subscription__none-title {
    margin-top: 30px;
  }

  h1 {
    font-size: 42px;
    line-height: 55px;
  }

  .main-title {
    font-size: 30px;
    line-height: 38px;
    &--mb {
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .main-padding-block {
    padding: 50px 20px;
  }

  .main-title {
    font-size: 25px;
    line-height: 32px;
    &--mb {
      margin-bottom: 30px;
    }
  }

  .description-html {
    p {
      margin-block-end: 0.5em;
      &:not(:first-child) {
        margin-block-start: 0.5em;
      }
    }
  }

  h1 {
    font-size: 32px;
    line-height: 39px;
  }

  .subscription__none-title {
    margin-top: 20px;
  }

  .primary-button {
    font-size: 13px;
    line-height: 16px;
  }
}
