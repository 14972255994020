.btn-arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  color: #ffffff;
  background-color: var(--secondaryTextColor);
  width: 37px;
  height: 37px;
  border-radius: 10px;
  transform: rotate(45deg);
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  svg {
    path {
      fill: var(--primaryColor);
    }
  }

  & > * {
    transform: rotate(-45deg);
  }
}

.club-nav-btn-arrow {
  margin-left: 15px;
}

.swiper-btn-arrow-prev {
  position: absolute;
  left: 3px;
  top: 50%;
  z-index: 300;

  transform: translateY(-50%) rotate(45deg);
}

.swiper-btn-arrow-next {
  position: absolute;
  right: 3px;
  top: 50%;
  z-index: 300;

  transform: translateY(-50%) rotate(45deg);
}

.club-arrow-nav-number {
  margin-right: 5px;

  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;

  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 42px;
  line-height: 55px;

  color: var(--secondaryTextColor);
}

.clup-arrow-nav-amount {
  margin: 0 0 10px 3px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: rgba(var(--primaryTextColorRgb), 0.5);
}
